.profile {
  &-sidebar {
    padding: 48px 20px;
    @include media-breakpoint-up(md) {
      border-right: 1px solid #e0e0e0;
      min-height: calc(100vh - 65px);
    }
    @include media-breakpoint-up(xl) {
      padding: 48px 50px;
    }
  }
}

.action-buttons {
  display: grid;
  gap: 0.5rem;

  @include media-breakpoint-up(lg) {
    display: flex;
    flex-direction: row-reverse;
  }
}
