.light.public-share-container{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  color: #121010;
  background: #EFEFEF !important;

  .public-share-chat-popup{
    position: relative;
    bottom: inherit;
    right: inherit;
  }

  .options-wrapper{
    padding: 8px;
    background-color: white;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    canvas{
      height: 100px !important;
      width: 100px !important;
      border-radius: 0 !important;
    }
  }
}
