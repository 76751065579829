.public-share-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: max-content;
  gap: 20px;
  padding:20px;
  min-height: 100vh;
  //write an animated linear gradient background that slowly changes colors over 15 seconds
  // background: linear-gradient(90deg, #ff0000, #ff8000, #ffff00, #80ff00, #00ff00, #00ff80, #00ffff, #0080ff, #0000ff, #8000ff, #ff00ff, #ff0080, #ff0000);
  // background-size: 3000% 3000%;
  // -webkit-animation: rainbow 60s ease infinite;
  // -z-animation: rainbow 60s ease infinite;
  // -o-animation: rainbow 60s ease infinite;
  // animation: rainbow 60s ease infinite;
  // move the background gradient to a fixed position after psuedo element here 
  &:after{
    content: " ";
    position: fixed;
    width: 3000%;
    height: 3000%;
    z-index: -1;
    background: linear-gradient(90deg, #ff0000, #ff8000, #ffff00, #80ff00, #00ff00, #00ff80, #00ffff, #0080ff, #0000ff, #8000ff, #ff00ff, #ff0080, #ff0000);
    background-size: 3000% 3000%;
    -webkit-animation: rainbow 60s ease infinite;
    -z-animation: rainbow 60s ease infinite;
    -o-animation: rainbow 60s ease infinite;
    animation: rainbow 60s ease infinite;
  }
  
  .public-share-chat-popup{
    position: relative;
    bottom: inherit;
    right: inherit;
  }
  .options-wrapper{
    padding: 8px;
    background-color: white;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    canvas{
      height: 100px !important;
      width: 100px !important;
      border-radius: 0 !important;
    }
  }
}
.dark + .public-share-container {
// need to fix the bg here
}
@-webkit-keyframes rainbow {
  0% {
    background-position: 0% 82%
  }

  50% {
    background-position: 100% 19%
  }

  100% {
    background-position: 0% 82%
  }
}

@-moz-keyframes rainbow {
  0% {
    background-position: 0% 82%
  }

  50% {
    background-position: 100% 19%
  }

  100% {
    background-position: 0% 82%
  }
}

@-o-keyframes rainbow {
  0% {
    background-position: 0% 82%
  }

  50% {
    background-position: 100% 19%
  }

  100% {
    background-position: 0% 82%
  }
}

@keyframes rainbow {
  0% {
    background-position: 0% 82%
  }

  50% {
    background-position: 100% 19%
  }

  100% {
    background-position: 0% 82%
  }
}

@keyframes fadein {
  to {
    opacity: 1;
    /* Fade to fully opaque */
  }
}
