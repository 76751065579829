.card{
    box-shadow: 0px 4px 52px rgba(0, 0, 0, 0.1);
}
.card-header {
    background-color: inherit;
    border-bottom: none;
    padding: 15px;
    .card-title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
    }
    .card-subtitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
    }
}
