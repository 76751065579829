.medium-modal{
    width: 100%;
    max-width: 375px;
}
.modal{
    .modal-content{
        .modal-header{
            padding: 15px;
            .modal-title{
                
            }
        }
        .modal-body{
            padding: 15px;
        }
        .modal-footer{
            padding: 15px;
        }
    }
}