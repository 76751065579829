.copyright,
.privacy {
  font-family: 'Inter';
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
}

.black-medium{
  color: $grey;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}

.grey{
  color: $grey;
}

a{
  text-decoration: none;
}
