@include media-breakpoint-down(sm) {
    .contact-card-item{
        flex-direction: column;
        align-items: center;
        .info-container{
            text-align: center;
        }
    }
    .top-detail-wrapper {
        display: flex;
        justify-content: center !important;
    }
    .booking-status-wrapper{
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center !important;
        .booking-time-wrapper{
            text-align: center !important;
        }
    }
}

.powered-by-oppy{
    margin:15px;
}
.gallery-wrapper{
    margin-top:50px;
}
.booking-container{
    display: flex;
    flex-direction: column;
    gap: 15px;
    .contact-card-item{
        gap: 10px;
        height: min-content;
    }
    .booking-status-wrapper{
        display: flex;
        width: 300px;
        flex-direction: column;
        align-items: end; 
          
    }
    .top-detail-wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        gap: 20px;
        flex-wrap: wrap;
        margin-top: 20px;
    }
    .booking-time-wrapper{
        text-align: end;
    }
    .map-wrapper{
        width: 100%;
    }
    .booking-cards-wrapper{
        transition: 300ms;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 20px;
        .address-details{
            width: 100% !important;
        }
        .booking-confirmation-card{
            transition: 300ms;
            position: relative;
            flex-grow: 1;
            display: flex;
            justify-content: center;
            flex-flow: column;
            width: auto;
            padding:20px;

        }
        .share-booking-card{
            max-width: 320px;
            align-self: start;
        }
    }
}
.cancel-button{
    width: 200px;
    height: fit-content;
    margin: 20px;
}
.address-title-wrapper{
    display: flex;
    flex-direction: column;
    .open-map-link{
        width: fit-content;
    }
}
