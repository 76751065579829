.dark.public-share-container {

  color: white;
  background: linear-gradient(#0F2336, #0C0818) !important;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: none;
    background-size: 1800% 1800%;
  }

  .public-share-chat-popup {
    position: relative;
    bottom: inherit;
    right: inherit;
  }

  .options-wrapper {
    padding: 8px;
    background-color: white;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    canvas {
      height: 100px !important;
      width: 100px !important;
      border-radius: 0 !important;
    }
  }
}
