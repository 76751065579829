@import './bootstrap';
@import './variables';
@import './fonts';
@import './landing';
@import './typography';
@import './globalNav';

//bootstrap-overrides
@import './modal_overrides';
@import './card_overrides';

@import './images';

// components
@import './globalNav';
@import './components/index';
@import './navigationV2';
@import './RemarkTooltip';
@import './Gallery';
@import './BookingConfirmation';
// @import './dark_mode';

$booking-max-width:1210px;
$booking-min-width: 300px;
$booking-width: 100%;

@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  img {
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    -ms-interpolation-mode: nearest-neighbor;
  }
}

.booking-wrapper {
  justify-content: center !important;
  display: flex;
  align-items: baseline;
  display: flex;
  width: 100%;
  .booking-card{
    max-width: max-content;
    border: none;
    padding: 15px;
    margin-top: 20px;
    display: flex;
    max-width: $booking-max-width;//fits up to 3 cards
    min-width: $booking-min-width;
    width: $booking-width;
    margin-bottom: 70px;
    // now I want to center cards evenly within the box using flexbox
    .card-subtitle{
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      flex-wrap: wrap;
      column-gap: 40px;
      .property-info-button{
        text-decoration: underline;
        text-underline-offset: 2px;
        cursor: pointer;
        font-size: 16px;
        opacity: 0.5;
        transition: 300ms;
        //add slow margin on hover and make fully opaque
        &:hover{
          opacity: 1;
          text-underline-offset: 15px;
          transition: 300ms;
        }
      }
    }
    .booking-card-body{
      justify-content: space-evenly;
    }
  }
  .bg-secure {
    background-color: #d3fbe3 !important;
    color: #219653 !important;
    padding: 6px 12px;
    font-size: 14px;
    border-radius: 5px;
    width: fit-content;
  }
}
.booking-footer-wrapper{
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 60px;
  z-index: 999999;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right:10px;
  background: white;
  display: flex;
  justify-content: center;
  box-shadow: 0px 4px 52px rgba(0, 0, 0, 0.1);
  .booking-footer-container{
    width: $booking-width;
    max-width: $booking-max-width;
    min-width: $booking-min-width;
    display: flex;
    justify-content: space-between;
  }
  .remark-label{
    font-size: 12px;
    display: flex;
    align-items: self-end;
    align-content: center;
    opacity: .5;
    transition: 300ms;
    img{
      margin-left: 4px;
      margin-bottom: -4px;
    }
    &:hover{
      opacity: .8;
      transition: 300ms;
    }
  }
}
.pricing-feature-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border: initial;
  min-height: 50px;
}

.hidden {
  display: none;
}

.base-container {
  min-height: 100vh;
}

.global-nav {
  border-bottom: 1px solid $border_color;
}

// override form label
.form-label {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.mb-32 {
  margin-bottom: 32px;
}

.ml-32 {
  margin-left: 32px;
}

.accordion-item {
  background-color: transparent;
  border: 1px solid transparent;
  box-shadow: 0px 2px 3px $map_wrapper_shadow;
  margin-bottom: 8px;
  &-button {
    border: 1px solid transparent;
    &:not(.collapsed) {
      background-color: #fff;
      box-shadow: none;
      color: $black_dark;
      &:after {
        transform: rotate(0deg);
      }
    }
    &:after {
      transform: rotate(-90deg);
    }
  }
  .accordion-collapse {
    background-color: #fff;
  }
}

.btn {
  position: relative !important;
}

.badge-pricing {
  position: absolute !important;
  top: -9px !important;
  right: -8px;
  z-index: 1;
  padding: 0;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $badge_pricing_bg !important;
}

.map-wrapper {
  height: 200px;
  position: relative;
  margin-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 6px 5px 11px -12px $black;
}

.link-inline {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: $link_inline_color;

  &:hover {
    cursor: pointer;
  }
}

.dnd-container {
  position: absolute;
  height: 100%;
  width: 100%;
}

.item {
  position: relative;

  &:before {
    content: '';
    padding-bottom: 56.6%;
    // width: 100%;
  }

  &-image {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    object-fit: cover;
    object-position: top;
    border-radius: 4px;
  }
}

.item-empty {
  background: $item_empty_bg;
  border-radius: 4px;
  width: 100%;
  height: 100%;
}

.dragging {
  border: 1px dotted red;
}

.delete-image {
  z-index: 1;
  position: absolute;
  transform: rotate(45deg) scale(0.6);
  border-radius: 50%;
  right: 10px;
  top: 0;
  background: $delete_image_bg;
  &:hover {
    cursor: pointer;
  }
}

.services-list {
  display: flex;
  .services-list-item {
    display: flex;
    .icon-container {
      display: flex;
      align-content: flex-start;
    }
    .services-list-columns {
      display: flex;
      flex-wrap: wrap;
      row-gap: 10px;
      column-gap: 40px;
      .list-left-column {
        display: flex;
        .title-container {
          display: flex;
          align-content: flex-start;
          flex-direction: column;
          max-width: 200px;
        }
      }
      .list-right-column {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
.avatar--round {
  border: 2px solid $black;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  object-fit: cover;
}
.contact-card-item {
  display: flex;
  gap: 10px;
  .icon-container {
    display: flex;
    align-content: flex-start;
  }

  .info-container {
    display: flex;
    align-content: flex-start;
    flex-direction: column;
    max-width: 200px;
  }

  .list-right-column {
    display: flex;
    flex-direction: column;
  }
}
