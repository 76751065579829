.services-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.service {

  &-card {
    cursor: pointer;
    width: 100%;
    box-shadow: 0px 4px 52px rgba(0, 0, 0, 0.1);
    border: none;
    overflow: hidden;
    max-width: 380px;
    // the last card in the row should be aligned left
    // &:last-of-type {
    //   margin-right: auto;
    // }
    .card-img-top {
        object-fit: cover;
        height: 200px;
    }
    .card-body {
        min-height: 125px;
        // margin-top: 10px;
    }
    & .add-btn {
      border: 2px solid #e0e0e0;
    }

    &--empty {
      box-shadow: none !important;
      background-color: transparent !important;
      border: 3px solid #e0e0e0 !important;
      min-height: 100%;

      &:hover {
        cursor: pointer;
      }

      .card-body {
        display: flex;
        align-self: center;

        @include media-breakpoint-up(sm) {
          flex-direction: column;
          align-self: inherit;
          justify-content: center;
        }
      }

      .plus-icon {
        width: 32px;
        margin-right: 24px;

        @include media-breakpoint-up(sm) {
          width: 50px;
          margin: 0 auto 40px;
        }
      }
    }
  }

  &-badge {
    border-radius: 10px;
    padding: 0 8px;
  }
}

.service-title-subtitle {
    display:flex;
    flex-direction: column;
    height: 61px;
}
