$base_blue: #2f80ed;
$background_light: #fcfcfc;
$background_dark: #121212;
$background_mid: #f4f5f7;
$purple: #9b51e0;
$lightBlue: rgba(47, 128, 237, 0.1);
$black: #000000;
$white: #FFF;
$grey: #828282;
$black_medium: #333333;
$black_dark: #231F20;
$row-color: #f9fafb;
$border_color: #e7e7e7;
$badge_pricing_bg: #eb5757;
$map_wrapper_shadow: rgba(0, 0, 0, 0.08);
$accordion_item_shadow: rgba(0, 0, 0, 0.08);
$delete_image_bg: #a7bad6;
$item_empty_bg: #a7bad6;
$link_inline_color: #4949C8;
$landing_title_color: $base_blue;
$landing_subtitle_color: $base_blue;

/* Brand Colors */
$green: #399E5A;
$red: #E63946;
$glaucous: rgba(80, 126, 192, 1);
$lavender-web: rgba(208, 221, 241, 1);
$white: rgba(255, 255, 255, 1);
$harvest-gold: rgba(213, 142, 11, 1);



$nav_background_color: $background_dark;
$nav_text_color: $white;
$nav_hamburger_menu_background: $background_dark;


// Typography
$font-family-base: 'Inter', sans-serif;

// Breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

// Spacing
$spacer: 1rem;

// Media Breakpoint Mixins
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: map-get($breakpoints, $name);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}
