.light.chat-popup {
  box-shadow: none;
  background-color: #FFF;

  &.closed {
    background: #3D3AC9;
  }

  .chat-header {
    position: relative;
    border: none;
    background: #E0E3E7;

    .window-size-menu {
      background-color: #fff;
     
      color: #141414;

      .active {
        background-color: rgb(73 73 200 / 84%);
        color: #fff;
      }
    }

    h4 {
      color: #1A1717;
    }
  }

  .messages-container {
    .message {
      &.message-user {
        align-self: flex-end;
        background-color: rgb(73 73 200 / 90%);;
        color: #fff;
      }

      &.message-assistant {
        align-self: flex-start;
        background-color: #b4b4b4 !important;
        color: #fff;

        .dot-typing {
          margin-left: 10px;
          position: relative;
          left: -9999px;
          width: 5px;
          height: 5px;
          border-radius: 5px;
          color: #141414;
          animation: light-dot-typing 1.5s infinite linear;
        }

        @keyframes light-dot-typing {
          0% {
            box-shadow: 9984px 0 0 0 #141414, 9999px 0 0 0 #141414, 10014px 0 0 0 #141414;
          }

          16.667% {
            box-shadow: 9984px -10px 0 0 #141414, 9999px 0 0 0 #141414, 10014px 0 0 0 #141414;
          }

          33.333% {
            box-shadow: 9984px 0 0 0 #141414, 9999px 0 0 0 #141414, 10014px 0 0 0 #141414;
          }

          50% {
            box-shadow: 9984px 0 0 0 #141414, 9999px -10px 0 0 #141414, 10014px 0 0 0 #141414;
          }

          66.667% {
            box-shadow: 9984px 0 0 0 #141414, 9999px 0 0 0 #141414, 10014px 0 0 0 #141414;
          }

          83.333% {
            box-shadow: 9984px 0 0 0 #141414, 9999px 0 0 0 #141414, 10014px -10px 0 0 #141414;
          }

          100% {
            box-shadow: 9984px 0 0 0 #141414, 9999px 0 0 0 #141414, 10014px 0 0 0 #141414;
          }
        }
      }
    }
  }

  .input-container {
    input {
      background-color: #F3F3F3;
      color: #000;

      &:focus {
        outline: none;
      }
    }

    .send-button {
      border: none;
      display: flex;
      position: absolute;
      right: 0;
      justify-content: center;
      align-items: center;
      margin-right: 16px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: rgb(73 73 200 / 84%);
      cursor: pointer;
    }
  }

  .chat-message-error {
    background-color: #f8d7da;
    color: #721c24;
    border-color: #f5c6cb;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
  }

  .revolving-icon {
    animation: revolving 2s linear infinite;
  }

  @keyframes revolving {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
