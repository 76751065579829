.profile {
  &-picture {
    border-radius: 50%;
    border: 1px solid $green;
  }

  &-dropdown {
    .dropdown-toggle {
      &:after {
        border-top: 7px solid !important;
        border-right: 7px solid transparent !important;
        border-left: 7px solid transparent !important;
        color: $green;
        vertical-align: 0 !important;
      }
    }
  }

  &-name {
    color: $green;
  }
}
