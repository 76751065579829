.tooltip-arrow {display: none !important;}
.tooltip-icon{
    background-image: url("/assets/svg/tooltip.svg");
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    height: 17px;
    width: 17px;
    background-position: 50%;
    cursor: pointer;
    margin-left: 7px;
    margin-right: 7px;
  }