.body{
  background-color: linear-gradient(rgb(21 21 21), rgb(0 0 0)) !important;
}

.dark.chat-popup {
  border: 1px solid #1F1F21;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  filter: drop-shadow(0px 0px 106px rgba(68, 118, 187, 0.2)) !important;
  background-color: #000000d7;
  color: #fff;

  &.closed {
    background: #3D3AC9;
  }

  .chat-header {
    filter: drop-shadow(2px 4px 6px rgba(23, 23, 23, 0.203)) !important;
    background: rgb(68, 118, 187);
    color: #fff;
    display: flex;
    cursor: pointer;
    justify-content: left;

    h4{
      color: white;
    }

    .window-size-menu {
      background-color: #5082c9;
      color: #fff;

      .active {
        background-color: #4476BB;
        color: #fff;
      }
    }
  }

  .messages-container {
    .message {
      &.message-user {
        background-color: #4476BB;
        color: #fff;
        text-align: right;
        * a {
            color: rgb(21, 38, 61) !important;
          }
        
          * a:hover {
            color: rgb(163, 208, 247) !important;
          }
        
          * a:visited {
            color: rgb(6, 12, 19) !important;
          }
      }

      &.message-assistant {
        align-self: flex-start;
        background-color: rgb(57, 57, 57);
        color: rgba(255, 255, 255, 0.921);

        * a {
          color: rgb(92, 160, 255) !important;
        }
        
        * a:hover {
          color: rgb(140, 201, 255) !important;
        }

        * a:visited {
          color: rgb(68, 119, 191) !important;
        }

        .dot-typing {
          margin-left: 10px;
          position: relative;
          left: -9999px;
          width: 5px;
          height: 5px;
          border-radius: 5px;
          color: #ffffff !important;
          animation: dark-dot-typing 1.5s infinite linear;
        }

        @keyframes dark-dot-typing {
          0% { box-shadow: 9984px 0 0 0 #ffffff, 9999px 0 0 0 #ffffff, 10014px 0 0 0 #ffffff; }
          16.667% { box-shadow: 9984px -10px 0 0 #ffffff, 9999px 0 0 0 #ffffff, 10014px 0 0 0 #ffffff; }
          33.333% { box-shadow: 9984px 0 0 0 #ffffff, 9999px 0 0 0 #ffffff, 10014px 0 0 0 #ffffff; }
          50% { box-shadow: 9984px 0 0 0 #ffffff, 9999px -10px 0 0 #ffffff, 10014px 0 0 0 #ffffff; }
          66.667% { box-shadow: 9984px 0 0 0 #ffffff, 9999px 0 0 0 #ffffff, 10014px 0 0 0 #ffffff; }
          83.333% { box-shadow: 9984px 0 0 0 #ffffff, 9999px 0 0 0 #ffffff, 10014px -10px 0 0 #ffffff; }
          100% { box-shadow: 9984px 0 0 0 #ffffff, 9999px 0 0 0 #ffffff, 10014px 0 0 0 #ffffff; }
        }

      }
    }
  }

  .input-container {

    input {
      border: 1px solid #303039;
      
      background-color: #1A1A21;
      color: #fff;

      &:focus{
        outline: none;
      }
    }

    .send-button {
      background-color: #4476BB;
    }
  }


  .chat-message-error {
    background-color: #f8d7da;
    color: #721c24;
    border-color: #f5c6cb;
  }

  .revolving-icon {
    animation: revolving 2s linear infinite;
  }

  @keyframes revolving {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
