@import url('https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap');

.landing {
  @include media-breakpoint-down(md) {
    padding: 0 20px;
  }
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 1px;
}

td{
  text-align:center;
  vertical-align: middle;
}
.pricing-check{
  color: $green;
}

.pricing-x, .pricing-check{
  font-size: 30px;
}

.landingFont {
  font-family: 'Inter';
  font-style: normal;
  letter-spacing: -0.02em;
}

.landingTitle {
  @extend .landingFont;
  color: #2d2d2d;
  font-size: 45px;
  font-weight: 900;

  span {
    color: $base_blue;
    position: relative;
    display: inline-block;

    &:before {
      content: '';
      background: $purple;
      height: 8px;
      width: 104%;
      bottom: 7px;
      left: -2%;
      position: absolute;
      opacity: 0.16;
      transform: rotate(-1deg);
      z-index: -1;

      @include media-breakpoint-up(sm) {
        height: 10px;
        bottom: 14px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    font-size: 60px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 72px;
  }
}

.landingSection {
  margin: 40px auto;

  @include media-breakpoint-up(md) {
    margin: 56px auto;
  }

  @include media-breakpoint-up(md) {
    margin: 102px auto;
  }
}

.unstyled {
  text-decoration: none;
}

.landing-hero {
  width: 100%;
  margin-top:20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  padding: 20px;
  @include media-breakpoint-up(xs) {
    margin-top: 100px;
  }
  .landing-hero-cta{
    width: 50%;
    position: relative;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding:10px;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
  .landing-hero-images{
    display: flex;
    min-width: 50%;
    width: 50%;
    position: relative;
    height: inherit;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
    & img{
      filter: drop-shadow(10px 2px 80px rgba(0, 34, 255, 0.2));
    }
  }

  &-circle,
  &-small-circle {
    position: absolute;
  }
  &-circle {
    top: 48px;
    left: 0;
    height: 174px;
    @include media-breakpoint-up(sm) {
      top: 128px;
    }
  }

  &-small-circle {
    bottom: 44px;
    right: 0;

    @include media-breakpoint-up(sm) {
      bottom: 19px;
    }
  }
}

.scrollBtn {
  position: relative;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%);
  border: none;
  background: none;
}

.landingSubTitle {
  @extend .landingFont;
  font-size: 32px;
  color: #2d2d2d;
  font-weight: 900;
  margin-bottom: 30px;

  @include media-breakpoint-up(sm) {
    font-size: 48px;
  }

  span {
    color: $base_blue;
  }
}

.h3 {
  font-family: 'Inter';
  font-size: 24px;
  margin-bottom: 12px;
}

.p {
  font-family: 'Inter';
  font-size: 16px;
}

.svg-icon {
  margin-bottom: 24px;
}

.callout {
  font-family: 'Reenie Beanie';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  color: $black-medium;
  transform: rotate(-2deg);

  @include media-breakpoint-up(sm) {
    font-size: 48px;
  }
}

.eyebrow {
  @extend .landingFont;
  font-size: 14px;
  color: $base_blue;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 4px;

  @include media-breakpoint-up(sm) {
    size: 18px;
    margin-bottom: 14px;
  }
}

.emailContainer {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 6px 6px 6px 16px;
  height: 53px;
  display: inline-block;

  @include media-breakpoint-up(sm) {
    padding: 8px 8px 8px 24px;
    height: 69px;
  }

  .btn {
    padding: 7px 29px;
    height: 40px;
    @include media-breakpoint-down(sm) {
      padding: 7px 10px;
    }

    @include media-breakpoint-up(sm) {
      padding: 13px 29px;
      height: 53px;
    }
  }
}

.landingInput {
  color: $black;
  opacity: 0.4;
  border: none;
  padding: 5px;
  height: 40px;

  &:focus {
    border-color: $purple;
  }

  @include media-breakpoint-down(sm) {
    max-width: 170px;
  }

  @media screen and (max-width: 374px) {
    max-width: 130px;
  }
}

.imageContainer {
  position: relative;
  filter: drop-shadow(10px 2px 80px rgba(0, 34, 255, 0.2));
}


.landing-hero-big-circle{
  position: fixed;
  top: 90px;
  left: -30px;
  z-index: -99999999;
  overflow: hidden !important;
}
.landing-hero-small-circle{
  position: fixed;
  bottom: 20px;
  right: 0px;
  overflow: hidden !important;
}

.demoImg {
  position: relative;
  @include media-breakpoint-up(md) {

  }
  @include media-breakpoint-up(lg) {
    max-width: 89%;
  }
}

.container-blue,
.container-purple {
  height: 90vw;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  position: relative;

  @include media-breakpoint-up(md) {
    height: auto;
  }
}

.container-purple {
  background: url('/assets/svg/purple-shape.svg') center center no-repeat;
  background-size: contain;
}

.container-blue {
  background: url('/assets/svg/blue-shape.svg') center center no-repeat;
  background-size: contain;
}

.maps,
.services,
.time {
  max-width: 90%;
  filter: drop-shadow(0px 14px 34px rgba(0, 0, 0, 0.28));
  @include media-breakpoint-up(md) {
    max-width: 100%;
  }
  @include media-breakpoint-up(lg) {
    max-width: 80%;
  }

  @include media-breakpoint-up(xl) {
    max-width: 60%;
  }
}

.maps,
.time {
  max-width: 70%;
  position: absolute;
  top: 0;
  left: 0;
  @include media-breakpoint-up(lg) {
    max-width: 80%;
  }
}

.time {
  position: absolute;
  top: 35%;
  left: 30%;
  @include media-breakpoint-up(md) {
    max-width: 50%;
  }
}

.maps {
  @include media-breakpoint-up(xl) {
    max-width: 50%;
  }
}

.services {
  filter: drop-shadow(0px 19px 74px rgba(0, 0, 0, 0.16));
}

// table

.landing-table {
  .td {
    @include media-breakpoint-up(sm) {
      padding: 16px 24px !important;
    }
  }

  .th-header {
    @include media-breakpoint-up(md) {
      padding: 16px 20px !important;
    }

    @include media-breakpoint-up(lg) {
      padding: 4px 24px 16px !important;
    }
  }

  .th,
  .th-large {
    @include media-breakpoint-up(md) {
      padding: 28px 20px 28px !important;
    }

    @include media-breakpoint-up(lg) {
      padding: 32px 24px 28px !important;
    }
  }

  .th-caption {
    @include media-breakpoint-up(sm) {
      padding: 12px 16px !important;
    }
  }
}

.price {
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  margin-bottom: 16px;

  sub {
    color: #6b7280;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }
}

.row-highlight {
  background-color: $row-color;
}

.enterprise {
  background-color: $lightBlue;
  position: relative;
  padding: 24px;

  @include media-breakpoint-up(sm) {
    align-items: center;
    justify-content: center;
    padding: 50px;
  }
}

.enterprise-title {
  color: $base_blue;
  font-size: 24px;
  line-height: 120%;
}

.black-circle {
  &-top,
  &-bottom {
    position: absolute;
    width: 70px !important;
    top: 0px;
    left: -11px;

    @include media-breakpoint-up(sm) {
      width: 90px !important;
    }
  }

  &-bottom {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 50%;
    width: 174px !important;
  }
}

// Testimonials
.testimonial {
  padding: 10px 0;
  position: relative;

  &:before {
    background-color: $background_mid;
    content: '';
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    width: 100%;
    left: 0;

    @include media-breakpoint-down(md) {
      width: calc(100% + 40px);
      left: -20px;
    }
  }

  &-popup {
    border: none !important;
    position: relative;

    &:after {
      position: absolute;
      bottom: -15px;
      left: calc(50% - 10px);
      content: '';
      width: 0px;
      height: 0px;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 15px solid white;
    }
  }

  .person {
    font-family: 'Inter';
    font-weight: 700;
    margin-bottom: 10px;
  }

  .carousel-indicators {
    bottom: -30px;

    button {
      border: 1px solid #2f80ed;
      background-color: white;
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }

    [data-bs-target] {
      background-color: #2f80ed;
    }
  }
}

.footer {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.landing-hero-v2 {
  width: 100%;
  margin-top:20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  padding: 20px;
  min-height: 50vh;
  @include media-breakpoint-up(xs) {
    margin-top: 100px;
  }
  .landing-hero-cta{
    width: 50%;
    position: relative;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding:10px;
    max-width: 680px;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
  .callout{
    margin-bottom: 40px;
  }
  .landing-hero-images{
    display: flex;
    min-width: 50%;
    width: 50%;
    position: relative;
    height: inherit;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
    & img{
      filter: drop-shadow(10px 2px 80px rgba(0, 34, 255, 0.2));
    }
  }
  .landing-hero-iframe-wrapper{
    display: flex;
    min-width: 50%;
    width: 50%;
    height: inherit;
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
    & iframe{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
