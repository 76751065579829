// .photo-list {
//     display: flex;
//     flex-wrap: wrap;
//     list-style-type: none;
//     background-color: $black_medium;
//     &:after {
//         content:'';
//         flex-grow: 10;
//         }

//     li {
//         height: 40vh;
//         flex-grow: 1;
//     }

//     .gallery-item {
//         max-height: 100%;
//         min-width: 100%;
//         object-fit: cover;
//         vertical-align: bottom;
//     }
// }

//     // ADVANCED

//     // Portrait

//     @media (max-aspect-ratio: 1/1) {
//         .photo-list {

//             li {
//                 height: 30vh;
//             }
//         }
//     }

//     // Short screens

//     @media (max-height: 480px) {
//         .photo-list {
//             li {
//                 height: 80vh;
//             }
//         }
//     }

//     // Smaller screens in portrait

//     @media (max-aspect-ratio: 1/1) and (max-width: 480px) {
//         .photo-list {
//             flex-direction: row;
//             li {
//                 height: auto;
//                 width: 100%;
//             }

//             .gallery-item {
//                 width: 100%;
//                 max-height: 75vh;
//                 min-width: 0;
//             }
//         }
//     }
#fullscreen-drop-pane{
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    opacity: 0;
    display: none;
}
.global-gallery-buttons-wrapper{
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin: 0px 10px 10px 20px;
    margin-bottom: 20px
}
.add-item-button{
    width: 330px !important;
    padding: 30px;
    height: 300px;
    margin: 40px;
}
.gallery-wrapper{
    padding-top:20px;
    padding-bottom:20px;
    background-color: $black_dark;
    width: 100vw;
    .gallery-title{
        align-items: center;
        justify-content: center;
        display: flex;
        color: $grey;
    }
    .gallery-item-list{
        transition: 300ms;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        gap: 20px;
        .gallery-item-wrapper{
            transition: 300ms;
            position: relative;
            flex-grow: 1;
            display: flex;
            justify-content: center;
            flex-flow: column;
            max-width: fit-content;
            &:hover {
                .photo-buttons-wrapper{
                    transition: 300ms;
                    opacity: 1;
                    z-index: 9;
                    i{

                    }
                }
            }
            .gallery-item{
                min-width: 320px;
                transition: 300ms;
            }
            img,iframe{
                width: auto;
                height: -webkit-fill-available;
                min-width: 100px;
                max-width: 100%;
                max-height: 40vh;
                object-fit: cover;
                max-width: fit-content;
                max-height: 40vh;
                min-height: 250px;
            }
            .video-wrapper{

            }
            .photo-buttons-wrapper{
                opacity: .1;
                i{
                    font-size: 20px;
                    cursor: pointer;
                    transition: 300ms;
                }
            }

        }
    }
}
//ICONS
.overlay{
    position:absolute;
    padding: 5px;
    border-radius: 100%;
    margin: 10px;
    overflow: hidden;
    z-index: 2;
    cursor: pointer;

    &.delete-button{
        background-color: rgb(255, 128, 128);
        &::after{
            content:'';
        }
    }
    &.download-button{
        right: 0;
        background-color: white;
        &::after{
            content:'';
        }
    }
    &.zoom-button{
        background-color: white;
        bottom: 0px;
        right: 0px;
    }
}

.broken-item{
    display:none !important;
}
.item-focused{
    transition: 300ms !important;
    width: 100vw !important;
    height: 100vh !important;
    max-height: fit-content !important;
    max-width: 100vw !important;
    object-fit: contain !important;

}

.img-cover{
    object-fit: cover;
    background-color: $black_dark
}
.img-contain{
    object-fit: contain;
    background-color: $black_dark
}

.attachment-file-name{
  color: white;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
