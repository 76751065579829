@media (max-width: 450px){
    .account-logo {
      height: 40px;
      max-height: 40px !important;
      max-width: 130px !important;
    }
    .ref-logo {
      height: 40px; 
      max-height: 40px !important;
      max-width: 130px !important;
    }
  }
  @media (max-width: 970px) {
    .burgertime-hidden{
      display:none !important;
    }
  }
  @media (min-width: 971px) {
      .booking-nav-hamburger{
          display: none !important;
      }
  }
  
  .booking-big-burger-menu{
      height: 100vh;
      width: 100vw;
      position: fixed;
      top:0px;
      right:0px;
      opacity: .9;
      transition: 300ms;
      background-color: $nav_hamburger_menu_background;
      z-index: 99;
      padding-left: 40px;
      h1{
        margin-top:25px;
      }
      &.hidden-menu { 
        opacity: 0;
        z-index: -99;
        height: 0px;
        width: 0px;
        border-bottom-left-radius: 100%;
        .booking-navigation-links{
          margin-top:-100vh;
        }
      }
      .booking-navigation-links{
          margin-top:30px;
          transition: 500ms;
          .booking-nav-link{
              padding-top: 20px;
              padding-bottom: 20px;
              font-size: 24px;
          }
      }
      .booking-nav-hamburger{
          position: absolute;
          top: 10px;
          right: 20px;
          opacity: 1 !important;
          .hamburger-layer{
              position: absolute;
              &:first-of-type{
                  transform: rotate(-45deg);
                   background-color: $white;
              }
              &:last-of-type{
                  transform: rotate(45deg);
                   background-color: $white;
              }
              &.close-x{
                background-color: $white;
              }
          }
          &:hover{
              opacity:1;
              transition: 300ms;
          }
      }
  }
  .logo-nav-container{
    display: flex;
    // background: linear-gradient(180deg, rgba(0,0,0,.74), transparent);
    width: 100vw;
    position: fixed;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    top:0px;
    left:0px;
    z-index: 2;
    .logo-container{
      height: 100%;
      display: flex;
      img{
          object-fit: contain !important;
          object-position: center !important;
          height: 100% !important;
      }
      .account-ref-logos{
        display: flex;
        align-items: center;
      }
    }
    .booking-navigation{
      color: $nav_background_color;
      display: flex;
      .booking-navigation-links{
        color: $nav_text_color;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .booking-nav-link{
          color: $nav_text_color;
          display: flex;
          align-items: center;
          padding: 20px;
        }
      }
    }
    .booking-transparent-navigation,.booking-big-burger-menu{
      a,h1,h2,h3{
        color: $nav_text_color;
      }
    }
      .booking-nav-hamburger{
          display: flex;
          align-content: space-between;
          flex-direction: row;
          flex-wrap: wrap;
          width: 40px;
          cursor: pointer;
          height: 25px;
          opacity: .7;
          transition: 200ms;
          margin-top: 30px;
          .hamburger-layer{
              background-color: $base_blue;
              height: 3px;
              width: 100%;
              &:last-of-type{
                  border-right:none !important;
              }
          }
          &:hover{
              opacity:1;
              transition: 300ms;
          }
      }
  }
  
  .account-logo {
    margin-top: 20px;
    margin-bottom: 20px;
    max-height: 75px;
    max-width: 200px;
    height: auto;
  }
  .ref-logo {
    margin-top: 20px;
    margin-bottom: 20px;
    max-height: 75px;
    max-width: 200px;
    height: auto;
  }

  .disable-body-scroll{
    overflow: hidden;
  }
