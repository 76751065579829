@import './styles/_base';

span{
  vertical-align:middle;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-link {
  color: #61dafb;
}

.modal-header {
  border-bottom: 0 none !important;
}

.modal-footer {
  border-top: 0 none !important;
}

.fw-medium {
  font-weight: 600;
}

.ht-48 {
  height: 48px;
}

.ht-96 {
  height: 96px;
}

.fs-48 {
  font-size: 48px;
}

.fs-36 {
  font-size: 36px;
}

.ht-46 {
  height: 46px !important;
}
.ht-120 {
  height: 120px !important;
}
.ht-76 {
  height: 76px !important;
}

.fw-medium-light {
  font-weight: 500;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-12 {
  font-size: 12px;
}

.fs-24 {
  font-size: 24px;
}

.fs-32 {
  font-size: 32px;
}

.go-back {
  line-height: 17px;
}

.medium-width {
  margin: 0 auto;
  width: 379px !important;
}

.large-width {
  margin: 0 auto;
  width: 848px !important;
}

.text-red-added {
  color: #eb5757;
}

.text-gray-added {
  color: gray;
}
.text-blue-added {
  color: #4949C8;
}

.text-align-center {
  text-align: center;
}

form input {
  height: 48px;
}

form button {
  height: 46px;
}

.alert-confirmation .modal-footer {
  margin-left: auto;
  margin-right: auto;
}

.remove-arrow .popover-arrow {
  display: none !important;
}

.required:after {
  content: ' *';
  color: red;
  font-weight: 500;
}

/*  Slider button start*/
.switch {
  position: relative;
  margin-top: 8px;
  width: 38px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}

/*  Slider button End */

/* Dropdown start*/

.dropdown button {
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 4px;
  background: #ffffff;
  width: 100%;
}
/* Dropdown end*/

.activePage {
  color: #4949C8;
}

.removeLinkClass {
  text-decoration: none;
  color: inherit;
}

.removeLinkClass:hover {
  text-decoration: none;
  color: inherit;
}

.small-icon {
  height: 50px;
  width: 50px;
}

.tier {
  width: 80px;
}

.service-icon, .mini-icon {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  margin-left: 8px;
  margin-right: 8px;
}


.btn-primary {
  color: #fff;
  background-color: $black_medium;
  border-color: $black_medium;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  color: #fff;
  background-color: #4949C8;
  border-color: #4949C8;
}

.status {
  font-size: 30px;
}

.bg-green {
  background-color: #d3fbe3 !important;
  color: #219653 !important;
  padding: 6px 12px;
  font-size: 14px;
  border-radius: 5px;
}

.bg-red {
  background-color: #fac7c7 !important;
  color: #c72b20 !important;
  padding: 6px 12px;
  font-size: 14px;
  border-radius: 5px;
}

.bg-yellow {
  background-color: #f3ebbc !important;
  color: #cab600 !important;
  padding: 6px 12px;
  font-size: 14px;
  border-radius: 5px;
}

.map-pin{
  height: 40px;
  width: 40px;
  margin-top: -40px;
  margin-left: -20px;
}

.email-button{
  color: white;
  cursor: pointer;
  background-color: #4949C8 !important;
}

.hidden{
  display: none;
}

.vjs-control.vjs-cloudinary-button.vjs-button{
  display: none !important;
}

.cld-video-player .vjs-control-bar:hover{
  z-index: 2;
}

@include media-breakpoint-up(md) {
  .gallery-list .cld-video-player:not(.vjs-fullscreen) .vjs-volume-panel.vjs-control {
    display: none;
  }
}

@include media-breakpoint-up(lg) {
  .gallery-list .cld-video-player:not(.vjs-fullscreen) .vjs-time-control{
    display: none;
  }
}

.three-dots{
  height: 25px;
  width: 25px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 0;
  opacity: 0.5;
}

.drag-n-drop-icon{
  z-index: 1;
  position: absolute;
  transform: rotate(45deg) scale(0.8);
  border-radius: 50%;
  left: 10px;
  top: 7px;
  background: #a7bad6;
  padding: 6px 10px;
  &:hover {
    cursor: pointer;
  }
}

.carousel-inner{
  height: 100% !important;
}

.trash-icon-bg{
  background-color: rgb(255, 128, 128);
  border-radius: 50%;
  padding: 5px;
  width: 35px;
  &:hover {
    cursor: pointer;
  }
}

.swal-wide{
  width: 80% !important;
}

.add-border{
  border-radius: 15px;
  border: 3px solid #75b9f8;
  padding: 2px;
}

.trash-icon-right{
  flex-wrap: wrap;
  justify-content: space-between;
}

.trash-icon{
  width: 20px !important;
  height: 20px !important;
}

.cursor-point{
  cursor: pointer;
}

@include media-breakpoint-up(md) {
  .verify-phone{
    border: 2px rgba(128, 128, 128, 0.377) solid;
    border-radius: 0.5em;
  }
}

.home-chat-popup{
  position: relative;
  bottom: inherit !important;
  right: inherit !important;
}

.home-chat-popup-wrapper {
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.custom-swal-upgrade-to-pro-popup {
  max-width: 770px !important;
  width: 100% !important;
  border-radius: 10px;
}

.hideArrows ::-webkit-inner-spin-button,
.hideArrows ::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  margin: 0 !important;
}

.hideArrows {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
}
