.sidebar {
  &-link {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #333333;

    @include media-breakpoint-up(md) {
      padding-left: 28px;
    }

    &.active,
    &:hover {
      color: #4949C8;
    }

    &.active {
      font-weight: 600;
    }
  }
}
