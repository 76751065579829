.chat-popup {
  min-width: 350px;
  width: 450px;
  height: 300px;
  // height: fit-content !important;
  // height should equal the greater of the two values
  height: max-content;
  max-width: 100vw;
  min-width: 350px;
  max-height: 750px;
  min-height: 500px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(28 28 30 / 91%);
  justify-content: space-between;
  z-index: 1000;
  transition: background-color 300ms ease, opacity 300ms ease, transform 150ms ease;

  &.open {
    transition: height 300ms ease;
  }

  &.open.resizing {
    transition: unset !important;
  }

  &.closed {
    transition: all 300ms ease, height 200ms ease, transform 150ms ease;
    min-width: auto !important;
    min-height: 60px;
    min-width: 60px important;
    width: 60px !important;
    box-shadow: none !important;
    border-radius: 50% !important;
    background: #CEFF1A;

    .chat-header {
      min-width: auto;
      min-height: 60px;
      min-width: 60px;
      border: none !important;
      background: none !important;
      padding: 0px !important;
    }

    .input-container {
      visibility: hidden;
      opacity: 0;
      width: 0px;
      transition: all 0ms !important;
      border-radius: 10px;
    }

    .messages-container {
      visibility: hidden;
      transition: 300ms;
      .message {
        opacity: 0;
        transform: translate(0px, 10px);
        visibility: hidden;
      }

    }

    .chat-message-error {
      opacity: 0;
      width: 0;
      visibility: hidden;
    }
  }

  .chat-header {
    position: relative;
    padding: 8px 20px 8px 20px;
    height: 54px;
    color: #fff;
    display: flex;
    // cursor: pointer;
    align-items: center;
    justify-content: left;
    margin: 0px;
    gap: 10px;
    border-radius: 10px;
    border:#007aff 1px solid;
    color: rgba(13, 23, 36, 0.8);
    transition: 300ms;
    background: linear-gradient(95.2deg, rgba(173, 252, 234, 1) 26.8%, rgba(192, 229, 246, 1) 64%);
    //make the animation that slowly moves the background gradient on loop
    background-size: 600% 600%;
    animation: gradient 15s ease infinite;

    //make the animation that slowly moves the background gradient on loop
    @keyframes gradient {
      0% {
        background-position: 0% 50%;
      }

      50% {
        background-position: 100% 50%;
      }

      100% {
        background-position: 0% 50%;
      }
    }

    .window-size-menu {
      background-color: #5082c9;
      border-radius: 8px;
      padding: 2px;
      color: #fff;

      .active {
        background-color: #4949C8;
        color: #fff;
      }

      .window-size-menu-item {
        border-radius: 4px;
        padding: 2px;
      }
    }

    h4{
      margin: 0px;
      padding: 0px;
      font-size: 16px;
      color: rgba(13, 23, 36, 0.8)
    }
  }

  .messages-container {
    height: 100%;
    min-height: fill-available;
    overflow-y: auto;
    padding: 20px 10px 20px 10px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    * a {
      // ensure all links have a smooth transition
        transition: 300ms;
      }
    .message {
      max-width: 80%;
      margin-bottom: 5px;
      padding: 5px 10px;
      border-radius: 5px;
      word-wrap: break-word;
      transition: 300ms;

      &.message-user {
        align-self: flex-end;
        border-radius: 10px 10px 0px 10px;
        background-color: #4949C8;
        color: #fff;
        // if it's the last user message in the group
      }

      &.message-assistant {
        align-self: flex-start;
        border-radius: 10px 10px 10px 0px;
        background-color: #3a3a3c;
        color: #fff;
      }
      &.message-function {
        display: none;
        align-self: center;
        background-color: #909090;
        font-size: small;
        color: #fff;
      }
    }
  }

  .input-container {
    position: relative;

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    margin-bottom: 0px;
    transition: all 100ms 100ms;
    flex-shrink: 0;

    input {
      flex-grow: 1;
      border: none;
      border-radius: 5px;
      padding: 5px;
      background-color: #3a3a3c;
      color: #fff;
    }

    .send-button {
      border: none;
      display: flex;
      position: absolute;
      right: 0;
      justify-content: center;
      align-items: center;
      margin-right: 16px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #4949C8;
      cursor: pointer;
    }
    .send-button.hidden {
      opacity: 0;
      visibility: hidden;
      transition: all 100ms ease;
    }
  }
}

.chat-message-error {
  background-color: #f8d7da;
  color: #721c24;
  border-color: #f5c6cb;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 0px;
  width:100%;
}

.dot-typing {
  margin: 5px 10px;
  position: relative;
  left: -9999px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  color: #ffffff;
  animation: dot-typing 1.5s infinite linear;
}

@keyframes dot-typing {
  0% { box-shadow: 9984px 0 0 0 #ffffff, 9999px 0 0 0 #ffffff, 10014px 0 0 0 #ffffff; }
  16.667% { box-shadow: 9984px -10px 0 0 #ffffff, 9999px 0 0 0 #ffffff, 10014px 0 0 0 #ffffff; }
  33.333% { box-shadow: 9984px 0 0 0 #ffffff, 9999px 0 0 0 #ffffff, 10014px 0 0 0 #ffffff; }
  50% { box-shadow: 9984px 0 0 0 #ffffff, 9999px -10px 0 0 #ffffff, 10014px 0 0 0 #ffffff; }
  66.667% { box-shadow: 9984px 0 0 0 #ffffff, 9999px 0 0 0 #ffffff, 10014px 0 0 0 #ffffff; }
  83.333% { box-shadow: 9984px 0 0 0 #ffffff, 9999px 0 0 0 #ffffff, 10014px -10px 0 0 #ffffff; }
  100% { box-shadow: 9984px 0 0 0 #ffffff, 9999px 0 0 0 #ffffff, 10014px 0 0 0 #ffffff; }
}

.revolving-icon {
  animation: revolving 2s linear infinite;
}

@keyframes revolving {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.react-draggable-dragging {
  cursor: move
}

.chat-popup-wrapper {
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  position: fixed;
}

.fixed-position {
  position: fixed !important;
}

.resize {
  .react-resizable {
    position: relative
  }

  .react-resizable-handle {
    position: absolute;
    width: 10px;
    height: 10px;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    background-position: bottom right;
  }

  .react-resizable-handle-w,
  .react-resizable-handle-e {
    height: 100%;
    cursor: ew-resize;
  }

  .react-resizable-handle-w {
    left: 0;
    bottom: 0;
  }

  .react-resizable-handle-e {
    bottom: 0;
    right: 0;
  }

  .react-resizable-handle-n,
  .react-resizable-handle-s {
    width: 100%;
    cursor: ns-resize;
  }

  .react-resizable-handle-n {
    top: 0;
  }

  .react-resizable-handle-s {
    bottom: 0;
  }
}
